import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import CsvDownloadPage from './Users';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import TopPage from './components/TopPage';
import TopPageAPI from './components/TopPageAPI';
import SpotInfo from './components/SpotInfo';
// import GraphComponent from './Graph';
// import UserCountPage from './Userscount';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<TopPageAPI />} />
          <Route path="/:id/spotsinfo" element={<SpotInfo />} />
          {/* <Route path="/graph" element={<GraphComponent />} />
          <Route path="/users/count" element={<UserCountPage />} /> */}
        </Routes>
      </div>
    </BrowserRouter >
  );
}

export default App;