const dummyDataCandidatesT = [
    {
        productName: 'ファンタ オレンジ',
        companyName: 'Coca-Cola',
        category: '炭酸飲料',
        posId: '135246',
        salesCount: 53,
        numAll: 100,
        startDate: '2024-09-11',
        endDate: '2024-09-15',
        spotId: 1
    },
    {
        productName: 'ポテトチップス のりしお',
        companyName: 'カルビー',
        category: 'スナック菓子',
        posId: '987654',
        salesCount: 120,
        numAll: 200,
        startDate: '2024-09-16',
        endDate: '2024-09-18',
        spotId: 1
    },
    {
        productName: 'キリン 生茶',
        companyName: 'キリン',
        category: 'お茶',
        posId: '654789',
        salesCount: 75,
        numAll: 150,
        startDate: '2024-09-12',
        endDate: '2024-09-16',
        spotId: 2
    },
    {
        productName: 'コカ・コーラ',
        companyName: 'Coca-Cola',
        category: '炭酸飲料',
        posId: '246135',
        salesCount: 92,
        numAll: 200,
        startDate: '2024-09-13',
        endDate: '2024-09-17',
        spotId: 3
    },
    {
        productName: 'チップスター コンソメ味',
        companyName: '森永製菓',
        category: 'スナック菓子',
        posId: '753951',
        salesCount: 60,
        numAll: 300,
        startDate: '2024-09-14',
        endDate: '2024-09-19',
        spotId: 2
    },
    {
        productName: 'キットカット',
        companyName: 'ネスレ',
        category: 'チョコレート',
        posId: '951357',
        salesCount: 85,
        numAll: 150,
        startDate: '2024-09-15',
        endDate: '2024-09-20',
        spotId: 3
    },
    {
        productName: 'アクエリアス',
        companyName: 'Coca-Cola',
        category: 'スポーツドリンク',
        posId: '852963',
        salesCount: 110,
        numAll: 200,
        startDate: '2024-09-12',
        endDate: '2024-09-14',
        spotId: 1
    },
    {
        productName: 'じゃがりこ サラダ味',
        companyName: 'カルビー',
        category: 'スナック菓子',
        posId: '789654',
        salesCount: 130,
        numAll: 300,
        startDate: '2024-09-16',
        endDate: '2024-09-19',
        spotId: 2
    },
    {
        productName: '午後の紅茶 レモンティー',
        companyName: 'キリン',
        category: '紅茶',
        posId: '456321',
        salesCount: 95,
        numAll: 150,
        startDate: '2024-09-14',
        endDate: '2024-09-18',
        spotId: 1
    },
    {
        productName: 'カントリーマアム',
        companyName: '不二家',
        category: 'クッキー',
        posId: '357852',
        salesCount: 70,
        numAll: 300,
        startDate: '2024-09-17',
        endDate: '2024-09-19',
        spotId: 3
    },
    {
        productName: 'ミルクティー',
        companyName: 'リプトン',
        category: '紅茶',
        posId: '654123',
        salesCount: 50,
        numAll: 200,
        startDate: '2024-09-12',
        endDate: '2024-09-14',
        spotId: 2
    },
    {
        productName: 'アーモンドチョコレート',
        companyName: '明治',
        category: 'チョコレート',
        posId: '147852',
        salesCount: 115,
        numAll: 300,
        startDate: '2024-09-18',
        endDate: '2024-09-20',
        spotId: 1
    },
    {
        productName: 'ペプシコーラ',
        companyName: 'サントリー',
        category: '炭酸飲料',
        posId: '963258',
        salesCount: 125,
        numAll: 200,
        startDate: '2024-09-19',
        endDate: '2024-09-22',
        spotId: 3
    },
    {
        productName: 'カルピスウォーター',
        companyName: 'アサヒ飲料',
        category: '乳酸菌飲料',
        posId: '357159',
        salesCount: 68,
        numAll: 150,
        startDate: '2024-09-20',
        endDate: '2024-09-23',
        spotId: 1
    },
    {
        productName: 'サッポロ 黒ラベル',
        companyName: 'サッポロビール',
        category: 'ビール',
        posId: '258369',
        salesCount: 80,
        numAll: 300,
        startDate: '2024-09-18',
        endDate: '2024-09-21',
        spotId: 2
    },
    {
        productName: 'トップバリュ おにぎり',
        companyName: 'イオン',
        category: 'おにぎり',
        posId: '741258',
        salesCount: 90,
        numAll: 100,
        startDate: '2024-09-13',
        endDate: '2024-09-15',
        spotId: 3
    },
    {
        productName: 'ジャスミン茶',
        companyName: 'サントリー',
        category: 'お茶',
        posId: '963741',
        salesCount: 130,
        numAll: 300,
        startDate: '2024-09-11',
        endDate: '2024-09-13',
        spotId: 2
    },
    {
        productName: 'ドロリッチ',
        companyName: 'グリコ',
        category: 'デザート飲料',
        posId: '159753',
        salesCount: 72,
        numAll: 200,
        startDate: '2024-09-14',
        endDate: '2024-09-16',
        spotId: 1
    },
    {
        productName: 'じゃがビー',
        companyName: 'カルビー',
        category: 'スナック菓子',
        posId: '123987',
        salesCount: 115,
        numAll: 150,
        startDate: '2024-09-15',
        endDate: '2024-09-18',
        spotId: 3
    },
    {
        productName: 'ピュレグミ',
        companyName: 'カンロ',
        category: 'グミ',
        posId: '753258',
        salesCount: 135,
        numAll: 300,
        startDate: '2024-09-17',
        endDate: '2024-09-19',
        spotId: 2
    },
    {
        productName: '麦茶',
        companyName: '伊藤園',
        category: 'お茶',
        posId: '852741',
        salesCount: 95,
        numAll: 100,
        startDate: '2024-09-11',
        endDate: '2024-09-13',
        spotId: 1
    },
    {
        productName: 'エアリアル チーズ味',
        companyName: 'ヤマザキナビスコ',
        category: 'スナック菓子',
        posId: '654987',
        salesCount: 140,
        numAll: 300,
        startDate: '2024-09-15',
        endDate: '2024-09-18',
        spotId: 3
    },
    {
        productName: 'チョコボール',
        companyName: '森永製菓',
        category: 'チョコレート',
        posId: '357654',
        salesCount: 50,
        numAll: 150,
        startDate: '2024-09-12',
        endDate: '2024-09-14',
        spotId: 2
    },
    {
        productName: '雪見だいふく',
        companyName: 'ロッテ',
        category: 'アイスクリーム',
        posId: '951753',
        salesCount: 75,
        numAll: 200,
        startDate: '2024-09-18',
        endDate: '2024-09-20',
        spotId: 1
    },
    {
        productName: 'マウントレーニア',
        companyName: '森永乳業',
        category: 'カフェラテ',
        posId: '456987',
        salesCount: 110,
        numAll: 150,
        startDate: '2024-09-20',
        endDate: '2024-09-23',
        spotId: 2
    },
    {
        productName: '爽 アイスクリーム',
        companyName: 'ロッテ',
        category: 'アイスクリーム',
        posId: '123654',
        salesCount: 90,
        numAll: 200,
        startDate: '2024-09-11',
        endDate: '2024-09-14',
        spotId: 3
    },
    {
        productName: 'ピザポテト',
        companyName: 'カルビー',
        category: 'スナック菓子',
        posId: '258147',
        salesCount: 120,
        numAll: 300,
        startDate: '2024-09-14',
        endDate: '2024-09-16',
        spotId: 1
    },
    {
        productName: 'コアラのマーチ',
        companyName: 'ロッテ',
        category: 'ビスケット',
        posId: '753951',
        salesCount: 130,
        numAll: 150,
        startDate: '2024-09-19',
        endDate: '2024-09-22',
        spotId: 3
    },
    {
        productName: 'ポンジュース',
        companyName: 'えひめ飲料',
        category: 'ジュース',
        posId: '456123',
        salesCount: 85,
        numAll: 100,
        startDate: '2024-09-16',
        endDate: '2024-09-18',
        spotId: 2
    },
    {
        productName: 'サクレ レモン',
        companyName: 'フタバ食品',
        category: 'アイスクリーム',
        posId: '147258',
        salesCount: 140,
        numAll: 300,
        startDate: '2024-09-13',
        endDate: '2024-09-15',
        spotId: 1
    },
    {
        productName: 'お〜いお茶 緑茶',
        companyName: '伊藤園',
        category: 'お茶',
        posId: '852147',
        salesCount: 95,
        numAll: 150,
        startDate: '2024-09-11',
        endDate: '2024-09-14',
        spotId: 2
    },
    {
        productName: 'ハイチュウ',
        companyName: '森永製菓',
        category: 'キャンディ',
        posId: '369258',
        salesCount: 65,
        numAll: 200,
        startDate: '2024-09-15',
        endDate: '2024-09-17',
        spotId: 3
    },
    {
        productName: 'サントリー天然水',
        companyName: 'サントリー',
        category: 'ミネラルウォーター',
        posId: '258741',
        salesCount: 120,
        numAll: 300,
        startDate: '2024-09-12',
        endDate: '2024-09-14',
        spotId: 1
    },
    {
        productName: 'チョコレート効果',
        companyName: '明治',
        category: 'チョコレート',
        posId: '123852',
        salesCount: 100,
        numAll: 150,
        startDate: '2024-09-16',
        endDate: '2024-09-19',
        spotId: 2
    },
    {
        productName: 'ブルーベリーガム',
        companyName: 'ロッテ',
        category: 'ガム',
        posId: '741963',
        salesCount: 55,
        numAll: 100,
        startDate: '2024-09-17',
        endDate: '2024-09-20',
        spotId: 3
    },
    {
        productName: '野菜生活100',
        companyName: 'カゴメ',
        category: 'ジュース',
        posId: '951159',
        salesCount: 75,
        numAll: 200,
        startDate: '2024-09-14',
        endDate: '2024-09-18',
        spotId: 1
    },
    {
        productName: 'メロンソーダ',
        companyName: 'サントリー',
        category: '炭酸飲料',
        posId: '852456',
        salesCount: 105,
        numAll: 300,
        startDate: '2024-09-11',
        endDate: '2024-09-13',
        spotId: 2
    },
    {
        productName: 'アーモンドピーク',
        companyName: '明治',
        category: 'チョコレート',
        posId: '147456',
        salesCount: 125,
        numAll: 200,
        startDate: '2024-09-15',
        endDate: '2024-09-19',
        spotId: 3
    },
    {
        productName: '黄金チキン',
        companyName: 'ローソン',
        category: 'お惣菜',
        posId: '654753',
        salesCount: 85,
        numAll: 100,
        startDate: '2024-09-12',
        endDate: '2024-09-15',
        spotId: 1
    }
]

export default dummyDataCandidatesT;