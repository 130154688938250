import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import MenuAppBar from './Nav';
import Toolbar from '@mui/material/Toolbar';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import dummyDataCandidatesT from './dummydata';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const dummyDataCandidates = dummyDataCandidatesT;

interface ProductData {
    productName: string;
    companyName: string;
    category: string;
    posId: string;
    salesCount: number;
    numAll: number;
    startDate: string;
    endDate: string;
    spotId: number;
}

let dummyData: ProductData[] = [];
for (let i = 0; i < 15; i++) {
    dummyData.push(dummyDataCandidates[Math.floor(Math.random() * dummyDataCandidates.length)]);
}

interface StoreInfo {
    id: number;
    store_name: string;
    store_address: string;
    store_phone: string;
    store_type: string;
    store_info1: string;
    store_info2: string;
    store_info3: string;
    store_info4: string;
    store_info5: string;
}

const SpotInfo: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [storeName, setStoreName] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedSpotId, setSelectedSpotId] = useState<number | 'all'>('all');
    const [sortOrder, setSortOrder] = useState<string>('none');
    const [data, setData] = useState<ProductData[]>(dummyData);

    useEffect(() => {
        if (id) {
            axios.get<StoreInfo>(`https://hirodaimaps.ikeda042api.net/api/v2/storeinfo/${id}`)
                .then(response => {
                    setStoreName(response.data.store_name);
                })
                .catch(error => {
                    console.error('Error fetching store info:', error);
                });
        }
    }, [id]);

    useEffect(() => {
        let filteredData = dummyData.filter(item =>
            item.productName.includes(searchTerm) ||
            item.companyName.includes(searchTerm) ||
            item.category.includes(searchTerm) ||
            item.posId.includes(searchTerm) ||
            item.salesCount.toString().includes(searchTerm) ||
            item.numAll.toString().includes(searchTerm) ||
            item.startDate.includes(searchTerm) ||
            item.endDate.includes(searchTerm) ||
            item.spotId.toString().includes(searchTerm)
        );

        if (selectedSpotId !== 'all') {
            filteredData = filteredData.filter(item => item.spotId.toString() === selectedSpotId.toString());
        }

        if (sortOrder === 'asc') {
            filteredData.sort((a, b) => (a.salesCount / a.numAll) - (b.salesCount / b.numAll));
        } else if (sortOrder === 'desc') {
            filteredData.sort((a, b) => (b.salesCount / b.numAll) - (a.salesCount / a.numAll));
        }

        setData(filteredData);
    }, [searchTerm, selectedSpotId, sortOrder]);

    const spotStyle = (spotId: number): React.CSSProperties => {
        const styles = [
            { width: '80px', height: '80px', top: '10px', left: '10px' },
            { width: '100px', height: '60px', top: '100px', left: 'calc(50% - 50px)' },
            { width: '60px', height: '100px', top: '180px', left: 'calc(100% - 70px)' },
        ];
        return {
            ...styles[spotId - 1],
            position: 'absolute',
            backgroundColor: selectedSpotId === spotId ? 'gray' : 'lightgray',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid black',
        };
    };

    const calculateCategorySalesRate = () => {
        const categorySales: { [key: string]: number } = {};
        const categoryTotals: { [key: string]: number } = {};

        data.forEach(item => {
            if (selectedSpotId === 'all' || item.spotId === selectedSpotId) {
                if (!categorySales[item.category]) {
                    categorySales[item.category] = 0;
                    categoryTotals[item.category] = 0;
                }
                categorySales[item.category] += item.salesCount;
                categoryTotals[item.category] += item.numAll;
            }
        });

        const categories = Object.keys(categorySales);
        const salesRates = categories.map(category => (categorySales[category] / categoryTotals[category]) * 100);

        const sortedData = categories.map((category, index) => ({
            category,
            salesRate: salesRates[index],
        })).sort((a, b) => b.salesRate - a.salesRate);

        return {
            categories: sortedData.map(item => item.category),
            salesRates: sortedData.map(item => item.salesRate),
        };
    };

    const calculateProductSalesRate = () => {
        const productSales: { [key: string]: number } = {};
        const productTotals: { [key: string]: number } = {};

        data.forEach(item => {
            if (selectedSpotId === 'all' || item.spotId === selectedSpotId) {
                if (!productSales[item.productName]) {
                    productSales[item.productName] = 0;
                    productTotals[item.productName] = 0;
                }
                productSales[item.productName] += item.salesCount;
                productTotals[item.productName] += item.numAll;
            }
        });

        const products = Object.keys(productSales);
        const salesRates = products.map(product => (productSales[product] / productTotals[product]) * 100);

        const sortedData = products.map((product, index) => ({
            product,
            salesRate: salesRates[index],
        })).sort((a, b) => b.salesRate - a.salesRate);

        return {
            products: sortedData.map(item => item.product),
            salesRates: sortedData.map(item => item.salesRate),
        };
    };

    const calculateCompanySalesRate = () => {
        const companySales: { [key: string]: number } = {};
        const companyTotals: { [key: string]: number } = {};

        data.forEach(item => {
            if (selectedSpotId === 'all' || item.spotId === selectedSpotId) {
                if (!companySales[item.companyName]) {
                    companySales[item.companyName] = 0;
                    companyTotals[item.companyName] = 0;
                }
                companySales[item.companyName] += item.salesCount;
                companyTotals[item.companyName] += item.numAll;
            }
        });

        const companies = Object.keys(companySales);
        const salesRates = companies.map(company => (companySales[company] / companyTotals[company]) * 100);

        const sortedData = companies.map((company, index) => ({
            company,
            salesRate: salesRates[index],
        })).sort((a, b) => b.salesRate - a.salesRate);

        return {
            companies: sortedData.map(item => item.company),
            salesRates: sortedData.map(item => item.salesRate),
        };
    };

    const { categories, salesRates: categorySalesRates } = calculateCategorySalesRate();
    const { products, salesRates: productSalesRates } = calculateProductSalesRate();
    const { companies, salesRates: companySalesRates } = calculateCompanySalesRate();

    const categoryChartData = {
        labels: categories,
        datasets: [
            {
                label: '販売率 (%)',
                data: categorySalesRates,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const productChartData = {
        labels: products,
        datasets: [
            {
                label: '販売率 (%)',
                data: productSalesRates,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    const companyChartData = {
        labels: companies,
        datasets: [
            {
                label: '販売率 (%)',
                data: companySalesRates,
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'カテゴリ別販売率' + (selectedSpotId === 'all' ? '全スポット' : ` (Spot ${selectedSpotId})`),
            },
        },
    };

    const productChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '商品別販売率' + (selectedSpotId === 'all' ? '全スポット' : ` (Spot ${selectedSpotId})`),
            },
        },
    };

    const companyChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '企業別販売率' + (selectedSpotId === 'all' ? '全スポット' : ` (Spot ${selectedSpotId})`),
            },
        },
    };

    return (
        <div>
            <MenuAppBar />
            <Toolbar />
            <Container maxWidth="lg">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        TOP
                    </Link>
                    <Typography color="text.primary">{storeName}</Typography>
                </Breadcrumbs>

                <div style={{
                    position: 'relative',
                    border: '2px solid black',
                    width: '100%',
                    height: '300px',
                    marginBottom: '20px',
                }}>
                    <div style={spotStyle(1)}>Spot 1</div>
                    <div style={spotStyle(2)}>Spot 2</div>
                    <div style={spotStyle(3)}>Spot 3</div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', width: '100%' }}>
                    <TextField
                        label="検索"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ flex: 1, marginRight: '20px' }}
                    />
                    <FormControl variant="outlined" style={{ minWidth: 120, marginRight: '20px' }}>
                        <InputLabel>Spot ID</InputLabel>
                        <Select
                            value={selectedSpotId}
                            onChange={(e) => setSelectedSpotId(e.target.value as number | 'all')}
                            label="Spot ID"
                        >
                            <MenuItem value="all">全て</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ minWidth: 160 }}>
                        <InputLabel>販売率でソート</InputLabel>
                        <Select
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                            label="販売率でソート"
                        >
                            <MenuItem value="none">ソートなし</MenuItem>
                            <MenuItem value="asc">販売率昇順</MenuItem>
                            <MenuItem value="desc">販売率降順</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">商品名</TableCell>
                                <TableCell align="center">会社名</TableCell>
                                <TableCell align="center">カテゴリ</TableCell>
                                <TableCell align="center">POS ID</TableCell>
                                <TableCell align="center">売り上げ数</TableCell>
                                <TableCell align="center">スポット展開数</TableCell>
                                <TableCell align="center">設置開始日時</TableCell>
                                <TableCell align="center">設置終了日時</TableCell>
                                <TableCell align="center">Spot ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{item.productName}</TableCell>
                                    <TableCell align="center">{item.companyName}</TableCell>
                                    <TableCell align="center">{item.category}</TableCell>
                                    <TableCell align="center">{item.posId}</TableCell>
                                    <TableCell align="center">{item.salesCount}</TableCell>
                                    <TableCell align="center">{item.numAll}</TableCell>
                                    <TableCell align="center">{item.startDate}</TableCell>
                                    <TableCell align="center">{item.endDate}</TableCell>
                                    <TableCell align="center">{item.spotId}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ marginTop: '40px' }}>
                    <Bar data={categoryChartData} options={chartOptions} />
                </div>
                <div style={{ marginTop: '40px' }}>
                    <Bar data={productChartData} options={productChartOptions} />
                </div>
                <div style={{ marginTop: '40px' }}>
                    <Bar data={companyChartData} options={companyChartOptions} />
                </div>
            </Container>
        </div>
    );
};

export default SpotInfo;