import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import MenuAppBar from './Nav';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

const API_URL = 'https://hirodaimaps.ikeda042api.net/api/v2/storeinfo/';

interface StoreInfoCreate {
    store_name: string;
    store_address: string;
    store_phone: string;
    store_type: string;
    store_info1: string;
    store_info2: string;
    store_info3: string;
    store_info4: string;
    store_info5: string;
}

interface StoreInfoResponse extends StoreInfoCreate {
    id: number;
}

const TopPageAPI: React.FC = () => {
    const navigate = useNavigate();
    const [stores, setStores] = useState<StoreInfoResponse[]>([]);
    const [open, setOpen] = useState(false);
    const [newStore, setNewStore] = useState<StoreInfoCreate>({
        store_name: '',
        store_address: '',
        store_phone: '',
        store_type: '',
        store_info1: '',
        store_info2: '',
        store_info3: '',
        store_info4: '',
        store_info5: '',
    });

    const [editMode, setEditMode] = useState(false);
    const [storeToUpdate, setStoreToUpdate] = useState<StoreInfoResponse | null>(null);

    useEffect(() => {
        axios.get<StoreInfoResponse[]>(API_URL)
            .then((response: AxiosResponse<StoreInfoResponse[]>) => {
                setStores(response.data);
            })
            .catch((error: unknown) => {
                console.error('Error fetching store info:', error);
            });
    }, []);

    const handleClickOpen = () => {
        setNewStore({
            store_name: '',
            store_address: '',
            store_phone: '',
            store_type: '',
            store_info1: '',
            store_info2: '',
            store_info3: '',
            store_info4: '',
            store_info5: '',
        });
        setEditMode(false);
        setOpen(true);
    };

    const handleClickOpenUpdate = (store: StoreInfoResponse) => {
        setNewStore(store);
        setStoreToUpdate(store);
        setEditMode(true);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddStore = () => {
        const storeToAdd: StoreInfoCreate = {
            ...newStore,
            store_info1: '',
            store_info2: '',
            store_info3: '',
            store_info4: '',
            store_info5: '',
        };

        axios.post<StoreInfoResponse>(API_URL, storeToAdd)
            .then((response: AxiosResponse<StoreInfoResponse>) => {
                setStores([...stores, response.data]);
                setOpen(false);
            })
            .catch((error: unknown) => {
                console.error('Error adding new store:', error);
            });
    };

    const handleUpdateStore = () => {
        if (storeToUpdate) {
            axios.put<StoreInfoResponse>(`${API_URL}${storeToUpdate.id}`, newStore)
                .then((response: AxiosResponse<StoreInfoResponse>) => {
                    const updatedStores = stores.map(store =>
                        store.id === storeToUpdate.id ? response.data : store
                    );
                    setStores(updatedStores);
                    setOpen(false);
                })
                .catch((error: unknown) => {
                    console.error('Error updating store:', error);
                });
        }
    };

    const handleDeleteStore = (id: number) => {
        axios.delete(`${API_URL}${id}`)
            .then(() => {
                setStores(stores.filter(store => store.id !== id));
            })
            .catch((error: unknown) => {
                console.error('Error deleting store:', error);
            });
    };

    return (
        <div>
            <MenuAppBar />
            <Toolbar>
                <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="div">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StoreMallDirectoryIcon />
                            店舗情報
                        </Box>
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleClickOpen}
                        sx={{
                            backgroundColor: 'gray',
                        }}
                    >
                        新しい店舗を追加
                    </Button>
                </Container>
            </Toolbar>

            <Container maxWidth="lg">
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{editMode ? '店舗情報をアップデート' : '新しい店舗を追加'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="店舗名"
                            fullWidth
                            value={newStore.store_name}
                            onChange={(e) => setNewStore({ ...newStore, store_name: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="店舗住所"
                            fullWidth
                            value={newStore.store_address}
                            onChange={(e) => setNewStore({ ...newStore, store_address: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="店舗電話番号"
                            fullWidth
                            value={newStore.store_phone}
                            onChange={(e) => setNewStore({ ...newStore, store_phone: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="カテゴリ"
                            fullWidth
                            value={newStore.store_type}
                            onChange={(e) => setNewStore({ ...newStore, store_type: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            キャンセル
                        </Button>
                        <Button onClick={editMode ? handleUpdateStore : handleAddStore} color="primary">
                            {editMode ? 'アップデート' : '追加'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>店舗ID</TableCell>
                                <TableCell align='center'>店舗名</TableCell>
                                <TableCell align='center'>店舗住所</TableCell>
                                <TableCell align='center'>店舗電話番号</TableCell>
                                <TableCell align='center'>カテゴリ</TableCell>
                                <TableCell>アクション</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stores.map((store) => (
                                <TableRow key={store.id}>
                                    <TableCell align='center'>{store.id}</TableCell>
                                    <TableCell align='center'>{store.store_name}</TableCell>
                                    <TableCell align='center'>{store.store_address}</TableCell>
                                    <TableCell align='center'>{store.store_phone}</TableCell>
                                    <TableCell align='center'>{store.store_type}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => navigate(`/${store.id}/spotsinfo`)}
                                        >
                                            スポット情報
                                        </Button>
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleClickOpenUpdate(store)}
                                        >
                                            アップデート
                                        </Button> */}
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeleteStore(store.id)}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            削除
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
};

export default TopPageAPI;